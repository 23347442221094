.container {
  display: flex;
  min-width: none;
  border-radius: 16px;
  border: 1px solid #cccccc;
  padding: 12px 16px;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  /* margin: 1rem; */
}

.focus {
  background: linear-gradient(#ffff, #fff) padding-box,
    linear-gradient(90deg, #eb3318 0%, #ff8a4a 100%) border-box;
  border: 1px solid transparent;
}

.input {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.03em;
  color: #000000;
  border: none;
  font-size: 14px;
  height: 22px;
  flex: 1;
}

.input::placeholder {
  color: #ababab;
}

.input:focus {
  outline: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: flex-end;
}