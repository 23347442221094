.container {
  display: flex;
  flex-direction: column;
  margin: 40px;
}

.grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 24px;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
