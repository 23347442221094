.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.table td,
.table th {
  padding: 12.5px;
}

.table td {
  word-wrap: break-word;
  max-width: 300px;
}

.border {
  border: 1px solid #d6dbdb;
}
