.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  z-index: 2;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(120, 25, 10, 0.2);
  border-radius: 16px;
  align-items: center;
  padding: 24px;
  gap: 24px;
  flex-direction: column;
  align-items: stretch;
  max-width: 435px;
  min-width: 435px;
}

.backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  z-index: 1;
}

@media screen and (max-width: 500px) {
  .content {
    max-width: 80vw;
  }
}
