.buttons {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.container {
  display: flex;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiomark {
  height: 20px;
  width: 20px;
  border: 2px solid #cccccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container input:checked ~ .radiomark {
  border: 2px solid #ff8a4a;
}

.radiomark:after {
  content: "";
  display: none;
}

.container input:checked ~ .radiomark:after {
  display: block;
}

.container .radiomark:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff8a4a;
}
