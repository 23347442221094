.card {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 348px;
  min-height: 182px;
  width: 348px;
  height: 182px;
  overflow: hidden;
}

.zeroPadding {
  padding: 0;
}

.backgroundImage {
  width: 100%;
  height: 100%;
}

.removeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-width: 0;
}
