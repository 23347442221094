.container {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.dropdown {
    max-height: 150px;
    overflow-y: scroll;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 6px 16px 0 16px;
    box-shadow: 0px 0px 20px rgba(120, 25, 10, 0.2);
    z-index: 20;
    margin-top: 30px;
}

.container select {
    display: none;
}