.container {
    padding: 40px;
}

.search-container {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    justify-content: flex-end;
    flex: 1;
}
