.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 32px;
}

.table tr {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    border: 1px solid #d6dbdb;
}

.table tr td {
    display: flex;
    align-items: center;
    padding: 16px 24px;
}
