.container {
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.event {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}