@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.container {
  display: flex;
  width: 242px;
  border-radius: 16px;
  border: 1px solid #cccccc;
  padding: 12px 16px;
  gap: 16px;
  align-items: center;
  /* margin: 1rem; */
  cursor: pointer;
  position: relative;
}

.focus {
  background: linear-gradient(#ffff, #fff) padding-box,
    linear-gradient(90deg, #eb3318 0%, #ff8a4a 100%) border-box;
  border: 1px solid transparent;
}

.input {
  letter-spacing: 0.05em;
  border: none;
  height: 22px;
  flex: 1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #353535;
  font-family: "Karla", sans-serif;
}

.input:disabled,
.input[disabled] {
  color: #333333;
}

.input::placeholder {
  color: #ababab;
}

.input:focus {
  outline: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: flex-end;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 12px;
  right: 12px;
  background-color: #ffffff;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #333333aa;
}

.date-picker {
  display: flex;
  gap: 14px;
  flex-direction: column;
  width: 255px;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 0px 0px 20px rgba(120, 25, 10, 0.2);
  align-self: flex-end;
  position: absolute;
  top: 60px;
  z-index: 10;
  background-color: #ffffff;
}

.date-picker-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table {
  width: 100%;
}

.table tr td {
  text-align: center;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.selected-date {
  background-color: #ff8a4a;
  color: #ffffff;
  border-radius: 24px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
