.container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 2px solid #cccccc;
  border-radius: 5px;
}

.container input:checked ~ .checkmark {
  background: #ff8a4a;
  border: 2px solid #ff8a4a;
}
