.container {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  gap: 0;
}
