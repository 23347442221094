.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tile {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ffd8c3;
  border-radius: 8px;
}

.activeTile {
  border: none;
  background: #ffd8c3;
  position: relative;
  border-radius: 7px;
  margin: 1px;
}

.activeTile::after {
  position: absolute;

  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(to right, #eb3318, #ff8a4a);
  content: "";
  z-index: -1;
  border-radius: 8px;
}

.arrow {
  cursor: pointer;
}
