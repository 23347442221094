.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #333333;
  height: 100vh;
  min-width: 160px;
  overflow-y: scroll;
  max-width: 284px;
}

.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - (2 * 16px));
}

.bottom-container {
  margin: 32px 0 48px 0;
}

.logo-container {
  display: flex;
  align-self: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 64px;
  margin-top: 48px;
  padding: 0;
}

.link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

/* NavLink class */
.link {
  text-decoration: none;
  font-size: 16px;
  width: calc(100% - 2 * 14px - 2 * 16px);
  max-width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 24px;
  border-radius: 12px;
}

/* NavLink class when it is active */
.active-link {
  background-color: #9a9a9a33;
}

.link-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
