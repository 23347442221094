.default {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.long {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 24px;
}
