.container {
  position: relative;
}

.options {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  margin-top: 8px;
}

.option {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}
.option:hover {
  background-color: #eee;
}

.selected {
  display: flex;
  flex-wrap: wrap;
}

.selectedItem {
  display: flex;
  align-items: center;
  margin: 4px;
  padding: 4px 8px;
}
