.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 400px;
}

.sports {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
