.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
  }
  
  .left {
    display: flex;
    gap: 16px;
    height: 100%;
  }
  
  .picture {
    width: 264px;
    height: 138px;
    border-radius: 16px;
    object-fit: cover;
  }
  
  .details {
    height: 100px;
    display: grid;
    grid-template-rows: 2fr 1fr;
  }
  
  .hidden {
    display: flex;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    justify-content: center;
    align-items: center;
  }
  
  .right {
    display: flex;
    align-items: center;
    gap: 16px;
  }