:root {
  --color-white: "#ffffff";
  --color-black: "#000000";
  --color-primary1-main: "#EB3318";
  --color-primary2-main: "#FF8A4A";
  --color-primary2-tint5: "#FFECE1";
  --color-secondary1-main: "#006C8D";
  --color-secondary2-main: "#40E0D0";
  --color-marking-red: "#D94029";
  --color-marking-green: "#67B36A";
  --color-gray-main: "#808080";
  --color-gray-gray1: "#ababab";
  --color-gray-gray2: "#cccccc";
  --color-gray-dark: "#333333";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* scrollbar setup */
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
