.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin: 40px 0;
  gap: 24px;
}

.text-container {
  display: flex;
  gap: 12px;
}

.backButton {
  width: 50px !important;
  height: 50px !important;
}
