.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 32px;
  border: none;
  border-radius: 100px;
  min-height: 40px;
  height: 50px;
}

.button[disabled] {
  cursor: default;
}

.plain {
  background: #ff8a4a;
}

.primary {
  background: linear-gradient(90deg, #eb3318 0%, #ff8a4a 100%);
}

.plain[disabled],
.primary[disabled] {
  background: #cccccc;
}

.secondary {
  background: linear-gradient(#ffff, #fff) padding-box,
    linear-gradient(90deg, #eb3318 0%, #ff8a4a 100%) border-box;
  border: 2px solid transparent;
  border-collapse: separate;
}

.secondary[disabled] {
  background: none;
  border: 2px solid #cccccc;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  padding: 0;
}
