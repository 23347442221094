.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 16px;
}

.row {
    width: 90%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 100px;
}