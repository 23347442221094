.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.photos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.photo {
  height: 180px;
  width: 150px;
  border-radius: 24px;
  object-fit: cover;
  border: 1px solid #FFF;
}

.hidden {
  display: flex;
  height: 180px;
  width: 150px;
  border-radius: 24px;
  border: 1px solid #cccccc;
  justify-content: center;
  align-items: center;
}
