.container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 40px;
}

.filterContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
