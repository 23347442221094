.container {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  padding: 40px 50px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  gap: 32px;
}

.namesContainer {
  display: flex;
  gap: 32px;
}

.iconUploadContainer {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.iconPreviewContainer {
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 48px;
}

.iconPreviewCross {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverUploadContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.covers {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.button {
  width: 185px;
  height: 50px;
}
