.container {
    display: flex;
    flex-direction: row;
    gap: 80px;
  }
  
  .cell {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  