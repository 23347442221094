.container {
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}