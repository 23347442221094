.container {
    padding: 40px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    gap: 32px;
    width: 100%;
}

.row[id="covers"] {
    flex-direction: column;
    gap: 8px;
}

.sports-selector {
    display: flex;
    flex-direction: column;
}

.sports-selector {
    display: flex;
    flex-direction: column;
}

.selector {
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 12px 5px;
    overflow: visible;
}

.select-item {
    margin-bottom: 5px;
}

.select-item:hover {
    cursor: pointer;
}

.sports-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
}

.sport {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 10px;
}