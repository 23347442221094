.container {
  display: flex;
  flex-direction: column;
  margin: 40px;
}

.header {
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.grid {
  display: flex;
  flex-direction: row;
  grid-gap: 64px;
}

.showInformation {
  margin-left: 63px;
  margin-bottom: 40px;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.detailsContainer {
  display: flex;
  gap: 68px;
}

.registerContainer {
  display: flex;
  gap: 80px;
}
