.container {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  padding: 40px 50px;
}

.spaceBetween {
  justify-content: space-between;
}

.detailsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 48px;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  gap: 48px;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sportIcon {
  width: 32px;
  height: 32px;
}

.removeButton {
  width: 50px;
  height: 50px;
}

.covers {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}
