.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 120px;
}

.left {
  display: flex;
  gap: 16px;
  height: 100%;
  align-items: center;
}

.picture {
  width: 264px;
  height: 138px;
  border-radius: 16px;
  border: 1px solid #fff;
  object-fit: cover;
}

.details {
  display: grid;
  grid-template-rows: 2fr 1fr;
}

.title {
  max-width: 600px;
  overflow-wrap: break-word;
}
