.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.contentContainer {
  max-height: 100%;
  overflow-y: scroll;
  width: 100%;
}
