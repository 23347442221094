@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.text {
  color: #353535;
  font-family: "Karla", sans-serif;
}

.h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.selection-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 22px;
}

.comment-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.comment-text-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.event-club {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 32px;
}

.name-tag {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.name-menu {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.button-name {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
