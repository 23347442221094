.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clubs {
  display: grid;
  grid-template-columns: repeat(2, 280px);
  gap: 16px;
}

.club {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.image {
  height: 90px;
  width: 60px;
  object-fit: cover;
  border-radius: 12px;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.club-details {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.detail {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
