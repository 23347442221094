.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid #cccccc;
  padding: 24px;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
